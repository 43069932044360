// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-index-js": () => import("./../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-position-filter-js": () => import("./../src/pages/careers/positionFilter.js" /* webpackChunkName: "component---src-pages-careers-position-filter-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eazy-features-js": () => import("./../src/pages/eazy/features.js" /* webpackChunkName: "component---src-pages-eazy-features-js" */),
  "component---src-pages-eazy-index-js": () => import("./../src/pages/eazy/index.js" /* webpackChunkName: "component---src-pages-eazy-index-js" */),
  "component---src-pages-eazy-pricing-js": () => import("./../src/pages/eazy/pricing.js" /* webpackChunkName: "component---src-pages-eazy-pricing-js" */),
  "component---src-pages-enterprise-index-js": () => import("./../src/pages/enterprise/index.js" /* webpackChunkName: "component---src-pages-enterprise-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-product-analytics-index-js": () => import("./../src/pages/product/analytics/index.js" /* webpackChunkName: "component---src-pages-product-analytics-index-js" */),
  "component---src-pages-product-click-and-collect-index-js": () => import("./../src/pages/product/click-and-collect/index.js" /* webpackChunkName: "component---src-pages-product-click-and-collect-index-js" */),
  "component---src-pages-product-customer-support-index-js": () => import("./../src/pages/product/customer-support/index.js" /* webpackChunkName: "component---src-pages-product-customer-support-index-js" */),
  "component---src-pages-product-index-js": () => import("./../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-logistics-index-js": () => import("./../src/pages/product/logistics/index.js" /* webpackChunkName: "component---src-pages-product-logistics-index-js" */),
  "component---src-pages-product-marketing-index-js": () => import("./../src/pages/product/marketing/index.js" /* webpackChunkName: "component---src-pages-product-marketing-index-js" */),
  "component---src-pages-product-operations-management-index-js": () => import("./../src/pages/product/operations-management/index.js" /* webpackChunkName: "component---src-pages-product-operations-management-index-js" */),
  "component---src-pages-product-scan-and-go-index-js": () => import("./../src/pages/product/scan-and-go/index.js" /* webpackChunkName: "component---src-pages-product-scan-and-go-index-js" */),
  "component---src-pages-product-website-and-mobile-app-index-js": () => import("./../src/pages/product/website-and-mobile-app/index.js" /* webpackChunkName: "component---src-pages-product-website-and-mobile-app-index-js" */),
  "component---src-pages-service-cloud-migration-index-js": () => import("./../src/pages/service/cloud-migration/index.js" /* webpackChunkName: "component---src-pages-service-cloud-migration-index-js" */),
  "component---src-pages-service-delivery-partner-integration-index-js": () => import("./../src/pages/service/delivery-partner-integration/index.js" /* webpackChunkName: "component---src-pages-service-delivery-partner-integration-index-js" */),
  "component---src-pages-service-development-cycles-index-js": () => import("./../src/pages/service/development-cycles/index.js" /* webpackChunkName: "component---src-pages-service-development-cycles-index-js" */),
  "component---src-pages-service-index-js": () => import("./../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-service-microservice-application-environment-index-js": () => import("./../src/pages/service/microservice-application-environment/index.js" /* webpackChunkName: "component---src-pages-service-microservice-application-environment-index-js" */),
  "component---src-pages-service-process-scaling-index-js": () => import("./../src/pages/service/process-scaling/index.js" /* webpackChunkName: "component---src-pages-service-process-scaling-index-js" */),
  "component---src-pages-technology-index-js": () => import("./../src/pages/technology/index.js" /* webpackChunkName: "component---src-pages-technology-index-js" */)
}

